import React from 'react';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
import ReactFormInputValidation from 'react-form-input-validation';
import axios from 'axios';
import Service from '../services/Service';
//import ReactDOM from 'react-dom';

class Otp extends React.Component {
  
    constructor(props){
      if(sessionStorage.getItem("mobile") == null){
           window.location = "/"
      }

        super(props);
        
        this.state = {
          otp_error:"",
          fields: {
            campaign_id: 1,
            mobile: sessionStorage.getItem("mobile"),
            otp:""
          },
          errors: {
              
          }
        };
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
        
            otp:"required|numeric|digits: 6"
        });
        this.form.onformsubmit = (fields) => {
            
            Service.normalPost('/user/validateotpserail', fields).then((res)=>{
               
                if(res.data.data.verified){
                    sessionStorage.setItem("token", res.data.data.access_token)
                    sessionStorage.removeItem("phone");
                    //window.location = "/spinwheel";
                    if(res.data.data.bill_status == 0){
                      //window.location = "/spinwheel";
                    }else{
                      if(res.data.data.bill_status == 1){
                        window.location = "/billupload";
                      }
                    }
                }else{
                    this.setState({otp_error : "Invalid Otp"})
                }

            }).catch((err)=>{
                window.location = "/registered"


            })
            

        }

        
    }
    handleClick(){
          
      Service.normalPost('/user/resendserialotp', {campaign_id: 1, mobile: sessionStorage.setItem("mobile"),customer_id: sessionStorage.getItem("cust_id")})
      .then(()=>{
        eval("toastr.info('OTP Sent Successfully')")
      }).catch(()=>{

        eval("toastr.error('Something is wrong')")
      })
    }
  render() {
    return <>
    <Navbar/>
    <Slider/>

<div className="container-fluid Otp-bg p-0">
      
           <div className="container">
               <div className="row">

                   <div className="col-lg-6 text-center">
                      <img src="images/homepage-desk-left-img.png" alt="" className="home-left-img" />
                      <img src="images/homepage-mob-left-img.png" alt="" className="home-mob-left-img" />
                   </div>

                   <div className="col-lg-6 col-lg-p0">
                     <div className="text-center">
                      <img src="images/spin-desk.png" alt="" className="home-spin-img" />
                     </div>
                    
                    <form className="floating-form-design"  onSubmit={this.form.handleSubmit}>
                      <div className="floating-form otp-form">
                            <h3 className="enter-details otp-details">Enter your OTP</h3>
                            <p className="otp-p-tag">An OTP(One Time Password) has been sent to
                                your registered mobile number</p>
                        <div class="floating-label otp-label">      
                          <input className="floating-input" type="tel" placeholder=" " maxLength="6"
                          name = "otp"
                          onBlur={this.form.handleBlurEvent}
                          onChange={this.form.handleChangeEvent}
                          value={this.state.fields.otp}
                          />
                          <span className="highlight"></span>
                        </div>
                        <label className="error">
                        {this.state.errors.otp ? this.state.errors.otp : ""}
                        </label>
                        <label className="error">
                            {this.state.otp_error}
                        </label>
                         <div className="resend-otp">
                            <p onClick={e => this.handleClick(e)}>Resend OTP</p>
                         </div>
                       <div className="text-center">
                        <button type="submit" className="btn submit-button-design">Submit</button>
                       </div>
                      
                    
                    </div>
                    </form>
                  </div>
               </div>
           </div>
   
   </div>


 </>
 ;
   
}
}

export default Otp;