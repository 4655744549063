
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
//import ReactDOM from 'react-dom';

class Page404 extends React.Component {
  render() {
    return <>
    <Navbar/>
    <Slider/>
    <div className="container-fluid">
    
    <div className="error">
       
        <div className="container">
            <div className="col-xs-12 ground-color text-center">
                
                <div class="container-error-404">
                    <div className="clip"><div className="shadow"><span className="digit thirdDigit">4</span></div></div>
                    <div className="clip"><div className="shadow"><span className="digit secondDigit">0</span></div></div>
                    <div className="clip"><div className="shadow"><span className="digit firstDigit">4</span></div></div>
                    <div className="errorh4">Sorry! Page not found</div>
                </div>
                <div className="text-center">
                    <Link to="/"><button type="submit" className="btn button-home">Home</button></Link>
                  </div>
            </div>
        </div>
    </div>

 </div>
 </>
 ;
   
}
}

export default Page404;