import React from "react";
import ReactDOM from "react-dom";
import Otp from './pages/Otp';
import Page404 from './pages/Page404';
import Spinwheel from './pages/Spinwheel';
import { ProtectedRoute } from "./services/protected.route";
import Home from './pages/Home';
import Registered from './pages/Registered';
import Uploadbill from './pages/Uploadbill';
import Winningarea from './pages/Winningarea';
import Thankyou from './pages/Thankyou';
import Thankyouamc from './pages/Thankyouamc';
import registeredotp from './pages/RegisterdOtp';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./styles.css";

function App() {
  return (
    <div >
      <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/registered" component={Registered} />
        <Route exact path="/otp" component={Otp} />
        <Route exact path="/spinwheel" component={Spinwheel} />
        <Route exact path="/billupload" component={Uploadbill} />
        <Route exact path="/winningarea" component={Winningarea} />
        <Route exact path="/thanksinvoice" component={Thankyou} />
        <Route exact path="/thanksamc" component={Thankyouamc} />
        <Route exact path="/registeredotp" component={registeredotp} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/contact" component={Contact} />
        <Route path="*" component={Page404} />
      </Switch>
    </BrowserRouter>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement
);
