import axios from 'axios';

export default axios.create({
  //baseURL: "http://staging32api.bigcityexperiences.co.in/v1",
  //baseURL: "http://havells/api/web/v1",
  baseURL:"http://havellsalkalineapi.bigcityexperience.com/v1",
  headers: {
    
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE"

  }
});