import http from "../http-common";

class Service {
    constructor(){
       // this.auth = true;
    }

    login(callback){
        
        callback()
    }

    create(data, url) {
        
        return http.post(url, data);
      }
    register( url, data){
      
        return http.post(url, data);
    }
    GetService(url){
        return http.get(url)
    }
    getServiceWithToken(url){
        return http.get(url, {headers: {'Authorization': 'Bearer '+sessionStorage.getItem("token")}})
    }
    postWithToken(url , data){
        return http.post(url, data, {headers: {'Authorization': 'Bearer '+sessionStorage.getItem("token")}})
    }
    normalPost(url, data){

        return http.post(url, data)
    }

}

export default new Service();