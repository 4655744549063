
import React from 'react';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
//import ReactDOM from 'react-dom';

class Thankyouamc extends React.Component {
  constructor(props){
    super(props);
    if(sessionStorage.getItem("name") == null){
      window.location = "/";
     }
    sessionStorage.clear()
  }
  render() {
    sessionStorage.clear()
    return <>
    <Navbar/>
    <Slider/>
 



<div class="container-fluid  upload-bg p-0">
      
           <div class="container">
               <div class="row">
                   <div class="col-lg-12">
                    <div class="text-center thank-sec">
                        <img src="images/design-top.png" alt="" class="design-pattern" />
                        <img src="images/mob-design-pattern.png" alt="" class="mob-design-pattern" /><br />
                        <img src="images/lamp.png" alt="" class="thank-lamp-img" /><br />
                        <h1 class="won-color-h1">Thank you</h1>
                        <h4 class="congrats-color-h4">For Participating</h4>
                        <p class="thank-para">Reward code is sent on your registered Mobile No. This coupon can be redeemed at the time of installation.
                            Alternatively, you can book a request at Havells call centre 8045771313 to claim.</p>
                        
                    </div>
                   </div>
             
               </div>
           </div>
   
   </div>
 </>
 ;
   
}
}

export default Thankyouamc;