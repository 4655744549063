
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
//import ReactDOM from 'react-dom';

class Terms extends React.Component {
    constructor(props){
        super(props);
        sessionStorage.clear()
    }
  render() {
      
    return <>
    <Navbar/>
    <Slider/>
    <div className="container-fluid terms-section p-0">
           <div className="container">
               <div className="row">
                <div className="terms-section-whole">
                    <section className="terms-main">
                        <br />
                       <h4 className="terms-head">Havells Water Purifier Diwali ka Tyohar- Terms &amp; Conditions </h4>
                       <br />
                       <ol className="p-10">
                           <li>Contest Details, Concept and Participation: 
                              <ol type="A">
                                  <li>These terms and conditions apply to the ‘Havells Water Purifier Diwali ka Tyohar’ campaign for consumers (hereinafter referred to as ‘Contest’) sponsored and launched by HAVELLS INDIA LTD. </li>
                                  <li>The contest is valid for a period starting 17th October 2020 to 15th December 2020 (hereinafter referred as the ‘Contest Period’). During the Contest Period, buy a Havells Water Purifier, Spin and Win 100% assured gift listed below: 
                                      <ol type="i">
                                         <li>Lloyd Front Load Washing Machine</li>
                                         <li>Lloyd Refrigerator</li>
                                         <li>Inverter Split AC </li>
                                         <li>Adonia 15 L Water Heater</li>
                                         <li>Instant Water Heater 3 L</li>
                                         <li>Rice Cooker </li>
                                         <li>Tea &amp; Coffee Maker</li>
                                         <li>Citrus Press Juicer </li>
                                         <li>Non-stick Tawa</li>
                                         <li>25% off on AMC</li>
                                      </ol> 
                                  </li>
                                  <li>The promotion is valid in all states of India except in the state of Tamil Nadu. </li>
                                  <li>Contest Period &amp; Contest Timings:</li>
                                  <li>All IPR rights including trademark, copy right and designs used are the property of their respective owners. </li>
                                  <li>The Contest is subject to all applicable central, state and local laws and regulations. </li>
                                  <li>Consumers participating or seeking to participate in the Contest shall individually be referred to as "Participant" and collectively as "Participants". </li>
                              </ol> 
                          </li>
                           <li>Agreement to Terms and Conditions: 
                              <ol type="A">
                                  <li>By participating in this contest, Participants fully and unconditionally agree to and accept these Terms and Conditions available on www.havellsalkaline.bigcityexperience.com ("Contest Website") and www.havells.com. The decisions of HAVELLS INDIA LTD. will be final and binding in all matters related to the Contest. Successfully entering the Contest and winning a prize is subject to all requirements set forth herein.  </li>
                                  <li>HAVELLS INDIA LTD. reserves the right to exclude any person from the contest on grounds of misconduct or for any other reasons, as it may deem fit and proper. No communication in the regards will be entertained. </li>
                                  <li>HAVELLS INDIA LTD. reserves the right to terminate, modify or extend the Contest and/or deletion or addition of any of the terms and conditions hereof, at any time at its absolute discretion, without advance notice and / or assigning any reason thereof. All decisions of HAVELLS INDIA LTD. in respect of the Contest and the prizes therein will be final, binding and conclusive. </li>
                              </ol> 
                           </li>
                           <li>Eligibility: 
                              <ol type="A">
                                  <li>This Contest will be open to all the residents of India with the exception of trade partners, employees and their immediate family members of HAVELLS INDIA LTD. and that of the agency. </li>
                                  <li>This contest is open for adults above 18 years.  </li>
                                  <li>HAVELLS INDIA LTD. or its partner agency will not be responsible for any NDNC (National Do Not Call) Registry regulation that will come into play. All Participants who participate will agree as per the terms and conditions that even if they are registered under NDNC, DND (Do Not Disturb), HAVELLS INDIA LTD. or its Agency will have all the authority to call such shortlisted winners by virtue of them having voluntarily participated in the Contest.  </li>
                              </ol> 
                           </li>
                           <li>Contest Period &amp; Contest Timings:
                              <ol type="A">
                                  <li>The contest will commence from 12.00:01 AM on 17/10/2020 to 11:59:59 PM on 15/12/2020 (both days included). </li>
                                  <li>Time of participation will be defined as the time at which the entry is received through the SMS mode of participation at HAVELLS INDIA LTD.  </li>
                                  <li>Entries coming outside the Contest Period will not be considered for winner selection.</li>
                                  <li>The contest shall run on Havells Water Purifier Range. </li>
                              </ol> 
                           </li>
                           <li>How to Participate: 
                              <ol  style={{listStyleType: "none"}}>
                                  <li>Step 1: Participant to SMS in the following format: ALKALINE to 9902391200  </li>
                                  <li>Step 2: Participant will receive a weblink. Participant to visit the weblink and enter the following information: Name, Mobile Number, Email ID, Product Serial Number. Upon Verification of the information provided by the Participant, the Participant will receive an OTP. Participant to enter the OTP  </li>
                                  <li>Step 3: Participant to Spin the Wheel on the webpage to reveal their reward. </li>
                                  <li>Step 4:  
                                      <ol type="a">
                                          <li> Participants who have won Gift, they will be required to upload their purchase invoice copy, installation no. and fill their shipping address details.  </li>
                                          <li>Participants who have won 25% off on Havells AMC, reward code will be sent on their registered Mobile No. </li>
                                      </ol> 
                                  </li>
                              </ol> 
                           </li>
                           <li>HAVELLS INDIA LTD. and its partner agency shall not be responsible for (including but not limited to): 
                              <ol type="A">
                                  <li>For any SPAM generated messages.  </li>
                                  <li>For the Operator Code not being displayed on the user's mobile phones.  </li>
                                  <li>For any SMS message delivery failures. </li>
                                  <li>Any lost, late or misdirected computer transmission or network, electronic failures or any kind of any failure to receive entries owing to transmission failures or due to any technical reason. </li>
                                  <li>If the Participant has registered himself to the DND of the telecom provider/ the Participant has registered with National Do Not Call Registry/ - Participant has specifically requested for not receiving messages for the specific campaign; and  </li>
                                  <li>Other conditions beyond its reasonable control. </li>
                              </ol> 
                           </li>
                           <li>Winner Selection: 
                              <ol type="A">
                                  <li>Any Participant can win a maximum of 1 prize during the Contest Period for a unique product serial number.    </li>
                                  <li>Once valid entry is verified against Serial number, Participant gets to play Spin the wheel to claim reward.  </li>
                                  <li>Winners will be selected by HAVELLS INDIA LTD. using randomizer software irrespective of the time of the entry through gamification. </li>
                                  <li>Prizes will be given subject to the winner satisfying the verification process of HAVELLS INDIA LTD.  </li>
                                  <li>The Prize cannot be transferred, exchanged, or redeemed for cash. In the event that the winner does not take the prize in the time and manner stipulated, then the prize will be forfeited by the winner and cash will not be awarded in lieu of the prize. Prizes must be taken as offered and cannot be varied. Any unclaimed prize is not-transferable, non-refundable, and non-redeemable for other goods or services and cannot be taken in exchange for cash.  </li>
                                  <li>Any unused prize is not-transferable, non-refundable, and non-redeemable for other goods or services and cannot be taken in exchange for cash. </li>
                                  <li>The image of the prize depicted on the press ads/posters/TVCs/pack shots etc. are for illustrative purposes only and the actual prize may vary from the depiction made. </li>
                                  <li>HAVELLS INDIA LTD. or its partner agency shall not be liable for any loss or damage of the prize due to force majeure event including but not limited to act of God, governmental action, and / or any other reasons which are beyond the reasonable control of HAVELLS INDIA LTD./ its partner agency, and under no circumstances, HAVELLS INDIA LTD/ its partner agency shall be liable to pay any compensation whatsoever for such losses.  </li>
                              </ol> 
                           </li>
                           <li>Contacting Winners:  
                              <ol type="A">
                                  <li>For Havells AMC Winners: The winners will receive their AMC claim code on their winning mobile number.  </li>
                                  <li>For Havells Merchandise Winners: Winners will receive their winning merchandise on their registered shipping address as entered on the promotional website www.havellsalkaline.bigcityexperience.com within 21 business days.   </li>
                                  <li>No prize will be awarded if the information presented by the Participant(s) at the time of entering the contest is not factually correct.  </li>
                                  <li>HAVELLS INDIA LTD. or its partner agency shall not be responsible for any accident or mishap or loss of the Participation Package (as defined later) by the Participant in transit.  </li>
                                  <li>The responsibility of ensuring that the Participation Package (as defined below) reaches the address as directed by HAVELLS INDIA LTD. or its partner (as defined below) agency lies solely with the participants. </li>
                                  <li>No justification/explanation for non-receipt of the Participation Package or any missing documents shall be accepted. </li>
                                  <li>For Gift Winner: Once the winners are selected, the partner agency appointed by HAVELLS INDIA LTD. shall process the gift for the winner as under: 
                                     <br />
                                      Step 1- In case the Gift Price is less than Rs 10000 then the partner agency will procure and dispatch the product on the shipping address provided by the winner on the microsite within 21 working days. 
                                      <ol type="a">
                                          <li>In the event as above (Step 2) the shortlisted winner's number is busy, unreachable, or he/she does not attend the call, two (2) more attempts will be made to reach the Participant on that day and/or the next day.   </li>
                                          <li> If even on the third attempt, the Participant does not attend the call, or is unreachable, the Participant's participation in the contest comes to an end and the Participant's entry stands cancelled and Participant becomes ineligible to receive the prize   </li>
                                          <li>In the event the shortlisted winner attends the call on either the second or third attempt, the procedure outlined in point (e) below shall apply. </li>
                                          <li>If any shortlisted winner remains unreachable on the third attempt or is not interested in continuing in the Contest or is otherwise ineligible to participate, the partner agency shall conduct up to two (2) more random selections to shortlist a Participant. If even after a third random selection, no winner is selected, no prize shall be declared in respect of the Contest. </li>
                                          <li> In case of a positive response from the shortlisted winner, each such shortlisted winner will be required to send the following documents and collateral: 
                                               <br />
                                              *Copy of Aadhar Card &amp; PAN Card Mandatory 
                                              <br />
                                              *No Objection Certificate and Gift Tax. 
                                              <br />
                                            This will be referred to as Participation Package for the winner.  </li>
                                          <li>The completed Participation Package needs to be sent by registered post with Due Acknowledgement to the address mentioned below or by courier so as to reach within 7 days of the call made by the partner agency to the selected winner: 
                                              <br /> 
                                              <br /> 
                                              <ol style={{listStyleType:"none"}}>
                                                  <li>Havells Water Purifier Diwali ka Tyohar.   </li>
                                                  <li>BigCity Promotions Pvt Ltd  </li>
                                                  <li>4th Floor, Mitra Towers </li>
                                                  <li>10/4 Kasturba Road   </li>
                                                  <li>Bangalore – 560001  </li>
                                              </ol> 
                                              <br />
                                          </li>
                                          <li>Upon receipt of the Participation Package, HAVELLS INDIA LTD. or its partner agency shall scrutinize all documents. If all the documents are in order and valid then the winner will be treated as a Confirmed Winner.  </li>
                                      </ol> 
                                  </li>
                              </ol> 
                           </li>
                           <li>Publicity:
                              <ol type="A">
                                  <li> The Participant/s undertake and irrevocably and unconditionally permit HAVELLS INDIA LTD. to cover the Contest through various media including newspapers, radio television news channels, internet, point of sale materials, etc., and shall not raise any objection, protest or demur to such coverage or in this regard. </li>
                                  <li>The winners must, at the request of HAVELLS INDIA LTD., participate in all promotional activity (such as publicity and photography) surrounding the winning of the prize, free of charge, and they consent to HAVELLS INDIA LTD. using their name and image in promotional material.  </li>
                              </ol>  </li>
                           <li>Intellectual Property:
                              <ol type="A">
                                  <li> All right, title and interest, including but not limited to the Intellectual Property Rights, in the promotional material(s) and in any and all responses received shall vest solely and exclusively with HAVELLS INDIA LTD. at all times. HAVELLS INDIA LTD. or any person or entity permitted by HAVELLS INDIA LTD. in this regard shall be entitled to use the responses received or any information in connection with the entry in any media for future promotional, marketing, publicity and any other purpose, without any permission and or payment to the Participant.  </li>
                                  <li>All material submitted in connection with the Contest (whether written, audio, electronic or visual form, or a combination of those) or any photographs, video and/or film footage and/or audio recording taken of Participants are assigned to HAVELLS INDIA LTD. upon submission and become the property of HAVELLS INDIA LTD. exclusively. HAVELLS INDIA LTD. may use the material in any medium in any reasonable manner it sees fit. Copyright in any such material remains the sole property of its respective owners.  </li>
                              </ol>     
                          </li>
                           <li>General Terms &amp; Conditions
                              <ol type="A">
                                  <li> Each Participant represents and warrants that he/she is legally competent to enter into binding contracts under applicable laws. By taking part and/or entering into the Contest the Participant warrants that all information provided by Participant regarding Participant's name, age, state, city, address, phone number, etc., is true, correct, accurate and complete.   </li>
                                  <li>The decision of HAVELLS INDIA LTD. or its partner agency, as regards the selection of winners and distribution of the prizes shall be final, subject only to HAVELLS INDIA LTD. 's approval. No correspondence or any other claims whatsoever, in this regard will be entertained. </li>
                                  <li>No queries, claims, dispute, complaints, or grievances shall be entertained by HAVELLS INDIA LTD. and its partner agency after 5 days from the date of the closure of the contest. </li>
                                  <li>All applicable taxes will be borne by the Winner. </li>
                                  <li>Wherever Gift Tax is applicable, the amount will be borne by the Winner.  </li>
                                  <li>The prize cannot be exchanged and is not transferable and cannot be converted into cash. </li>
                                  <li>HAVELLS INDIA LTD. reserves the right to change, defer, alter or cancel this Contest in part or full, or change any or all of the terms and conditions that are applicable, without giving prior intimation/notice of any kind and will not be liable for any consequential losses/damages. Any decision of HAVELLS INDIA LTD. in this regard and in general with respect to this Contest and the terms and conditions thereto shall be final, binding and conclusive. </li>
                                  <li>Nothing contained herein amounts to a commitment or representation by HAVELLS INDIA LTD. to conduct further Contests. </li>
                                  <li>The decision of HAVELLS INDIA LTD. shall be final in all regards and no communication shall be entertained in this regard. None of the provisions of these Terms and Conditions shall be deemed to constitute a partnership or agency between any Participant and HAVELLS INDIA LTD. and the Participant shall not have the authority to bind HAVELLS INDIA LTD. in any manner whatsoever. </li>
                                  <li>HAVELLS INDIA LTD. or any of its respective officers/employees/directors shall not be responsible for delayed, lost, mutilated, corrupted or illegible documents etc. </li>
                                  <li>HAVELLS INDIA LTD. or its partner agency shall not be liable to perform any of its/their respective obligations under the Contest or in respect of the prize where it is unable to do so as a result of circumstances beyond its/their control in the nature of fire, explosions, natural calamities, state emergency, riots, or any other Force Majeure condition, etc., and shall not be liable to compensate the Participant in these circumstances. </li>
                                  <li>HAVELLS INDIA LTD. or its partner agency shall not be accountable/liable for any disruptions/stoppages/interruptions or cancellation of the Contest due to any government restrictions. </li>
                                  <li>Failure to exercise or delay in exercising a right or remedy provided hereunder or by law does not constitute a waiver of the right or remedy or waiver of other rights or remedies on the part of HAVELLS INDIA LTD. or its partner agency. </li>
                                  <li>HAVELLS INDIA LTD. or its partner agency accepts no responsibility for late, lost, incomplete, incorrectly submitted, delayed, illegible, corrupted or misdirected entries, loss of SMS, loss of internet connectivity, unique codes, documents, demand drafts, claims or correspondence whether due to error, omission, alteration, tampering, unauthorized data hacking, deletion, theft, destruction, virus attack, transmission interruption, communications failure, hardware failure or otherwise. HAVELLS INDIA LTD. or its partner agency shall not be liable for any consequences of user error including (without limitation) costs incurred. </li>
                                  <li>If a Participant is dissatisfied with the Contest or the Contest rules and/or any content or any requirements of the Contest form, the materials available related thereto, or with these terms and conditions, his/her sole and exclusive remedy is to not participate in the Contest. </li>
                                  <li>If a person chooses to access the Contest Website from outside India, he/she shall do so on his/her own initiative and shall be responsible for compliance with applicable local laws of the country. </li>
                                  <li>The participation in the Contest is purely voluntary and the same shall be construed as an acceptance of the terms and conditions stipulated herein. </li>
                                  <li>Validity of Prize cannot be extended, OR new Prize cannot be provided against the expired/unused Vouchers. </li>
                                  <li>For any queries, call us on 080 4055 4868 from Mon-Sat, between 10:30 AM to 05:30 PM or you can also email us at feedback@bigcity.in </li>
                                  <li>All disputes arising out of or in connection to this scheme are subject to exclusive jurisdiction of the courts in New Delhi only. </li>
                              </ol>          
                          </li>
                       </ol>
                    </section>
                  
                  
                      <section className="terms-main"> 
                          <br />
                          <h5 className="retailer-text-center">Retailer Lucky draw offer (Pan India except TN) </h5>
                          <br />
                         <h5 className="terms-sub">Diwali ka tyohaar – Immunity aur uphaar: Festival Luckydraw 1st October, 2020 to 30th November, 2020.  </h5>
                         <br />
                         <h5 className="terms-sub">Dear Trade Partner  </h5>
                         <br />
                         <span>At the outset we, Havells India Limited (“Company”) would like to thank you for extending your support in promoting Havells Range of Water Purifiers from your esteemed retail outlet.  </span>
                          <br />
                         <span>To further boost the sales of Havells Water Purifiers during festival season, we are pleased to announce festive lucky draw offer (“Scheme”). </span>
                          <br />
                          <span>Under the Scheme, we will conduct 2 lucky draws - one in first week of November, 2020 (for sell-out done in October, 2020) and second in first week of December, 2020 (for sell-out done in November, 2020). </span>
                           <br />
                           <span>All  sell-out, where a retailer has earned tertiary sales incentive is eligible for lucky draw. </span>
                           <br />
                           <span>For every sell-out of 1 unit during the Term, Trade Partner will be issued one e-coupon. Higher number of  eligible sell-out means more e-coupons and higher probability of winning in lucky draw. </span>
                           <br />
                           <span>To qualify for the Scheme, a retailer must</span>
                           <br />
                           <ol type="1">
                            <li>
                                Sell-out a minimum of 3 units in a given calendar month i.e. minimum 3 units in the month of October, 2020 for lucky draw in November and similarly, minimum 3 units in the month of November, 2020 for lucky draw in December, 2020  
                            </li>
                            <li>
                                During the same period same retailer / dealer needs to buy in equal or more number of units. 
                            </li>
                         </ol> 
                    
                         <br />
                         <span>Dates of lucky draw will be announced in advance </span>
                         <br />
                         <br />
                    
                         <h6>Terms &amp; Conditions</h6>
                         <ol className="p-10">
                             <li>This Scheme is applicable for the period commencing on 1st October to 30th November 2020, both days inclusive (Term). The Term can be increased or decreased at the sole discretion of the Company.</li>
                             <li>This scheme can be availed only by the retailers registered with Company under its Dealer Management System (DMS) and is applicable on purchase of Water Purifiers from authorized distributor only.  </li>
                             <li>Direct dealers and Galaxy partners (direct billing partners) are also eligible to participate in the Scheme. </li>
                             <li>Only the sales by the registered retailers, recorded in our CRM as a sell-out to the customers within the defined geography during the term will be considered for the Scheme.  </li>
                             <li>To be eligible to participate in lucky draw : A minimum of 3 units of sell-out by retailer / dealer and 3 units of sell-in to retailer / dealer </li>
                             <li>Gift tax as applicable for high value gift (over Rs 10,000) will be borne by the winner. </li>
                             <li>Company reserves the right to withdraw and/or modify any of the terms &amp; conditions of the Scheme at any given point and decision of the Company shall be final and binding. </li>
                             <li> Any dispute related or arising from the Scheme shall be subject to jurisdiction of Delhi Courts. </li>
                             <li>All models of water purifiers are available without this Scheme as well. </li>
                             <li>These gifts cannot be exchanged or redeemed for cash  </li>
                          
                         </ol>
                      </section>
                    
                  </div>
            </div>
        </div>
   </div>
 </>
 ;
   
}
}

export default Terms;