import React from 'react';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
import ReactFormInputValidation from 'react-form-input-validation';
import Service from '../services/Service';
//import ReactDOM from 'react-dom';

class Registered extends React.Component {
    constructor(props){
        sessionStorage.clear()
        super(props);
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("phone");
        this.state = {
            serial_error:"",
            product_serial_number_error:"",
            fields: {
              campaign_id: 1,
              product_serial_number: "",
              
              
            },
            errors: {
              
            }
          };
          this.form = new ReactFormInputValidation(this);
          this.form.useRules({
        
            product_serial_number:"required"
        });
        this.form.onformsubmit = (fields) => {
          this.setState({product_serial_number_error : ""})
          Service.normalPost('/user/validateregisteredserial', fields)
          .then((res)=>{
            
        
            sessionStorage.setItem("name", res.data.data.customername);
            sessionStorage.setItem("mobile", res.data.data.mobile);
            sessionStorage.setItem("cust_id", res.data.data.customer_id);
            sessionStorage.setItem("img_url", res.data.data.image_path);
            sessionStorage.setItem("message", "You Have Won")
            if(res.data.data.status == 3){
              this.setState({product_serial_number_error : "You have already claimed the reward"})
            }else{
              Service.normalPost("/user/reregistation", {campaign_id: 1, product_serial_number: this.state.fields.product_serial_number})
            .then((res)=>{
               window.location = "/registeredotp";
            }).catch((err)=>{
              if(err.response.data.data.message == "\"Serail number already used.\"")
              {
                this.setState({product_serial_number_error : "You have already claimed the reward"})
              }
              if(err.response.data.data.message == "\"Please enter the correct serail number.\""){
                this.setState({product_serial_number_error : "Serial Number Not Found"})

              }

            })

            }
            
           
            }).catch((err)=>{
            
            if(err.response.data.data.message == "\"Invalid\""){
              this.setState({product_serial_number_error : "Serial Number Not Found"})
            }
            //this.setState({submit_disable : false})
            //this.setState({serial_error : "Inavlid Serial Number"})
            })

        }
    }
  render() {
    return <>
    <Navbar/>
    <Slider/>
    

<div class="container-fluid Otp-bg p-0">
      
           <div class="container">
               <div class="row">

                   <div class="col-lg-6 text-center">
                      <img src="images/homepage-desk-left-img.png" alt="" class="home-left-img" />
                      <img src="images/homepage-mob-left-img.png" alt="" class="home-mob-left-img" />
                   </div>

                   <div class="col-lg-6 col-lg-p0">
                     <div class="text-center">
                      <img src="images/spin-desk.png" alt="" class="home-spin-img" />
                     </div>
                    
                    <form class="floating-form-design" onSubmit={this.form.handleSubmit}>
                      <div class="floating-form otp-form">
                            <h3 class="enter-details otp-details">Enter serial number</h3>
                            
                        <div class="floating-label otp-label">      
                          <input class="floating-input" type="text" placeholder=" " 
                           name = "product_serial_number"
                           onBlur={this.form.handleBlurEvent}
                           onChange={this.form.handleChangeEvent}
                           value={this.state.fields.product_serial_number}
                           />
                          <span class="highlight"></span>
                        </div>
                         <div class="resend-otp">
                         </div>
                         <label className="error">
                        {this.state.errors.product_serial_number ? this.state.errors.product_serial_number : ""}
                        </label>
                        <label className="error">
                              {this.state.product_serial_number_error}
                        </label>
                       <div class="text-center">
                        <button type="submit" class="btn submit-button-design">Submit</button>
                       </div>
                      
                    
                    </div>
                    </form>
                  </div>
               </div>
           </div>
   
   </div>
 </>
 ;
   
}
}

export default Registered;