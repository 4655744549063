
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';

//import ReactDOM from 'react-dom';

class Winningarea extends React.Component {
  
  constructor(props){
    
    if(sessionStorage.getItem("message") == ""){
      window.location = "/"
    }
    super(props)
    this.state = {
      image_url : sessionStorage.getItem("img_url"),
      message: sessionStorage.getItem("message"),
     linking:""

    }
    
    if(sessionStorage.getItem("setPage") == "AMC"){
      this.setState({linking: "/thanksamc"})
     }else{
      this.setState({linking: "/billupload"})
     }
   
  }
  render() {
    
    return <>
    <Navbar/>
    <Slider/>
    
  <div class="container-fluid congrats-bg p-0">
      
           <div class="container">
               <div class="row">

                <div class="col-lg-12 congrats-section">
                    <img src="images/lamp.png" alt="" class="lamp-img" />
                    <h4 class="congrats-color-h4">Congratulations!</h4>
                    <h1 class="won-color-h1">You have won</h1>
                 <img src={this.state.image_url}  alt="" class="product-img" />
                 <h4 class="product-name">{this.state.message}</h4>
                 <div class="text-center">
                 <Link to={sessionStorage.getItem("setPage")}><button type="button" class="btn congrats-button-design">Proceed</button></Link>
                   </div>
                </div>
            
               </div>
           </div>
   
   </div>

 </>
 ;
   
}
}

export default Winningarea;