import React from 'react';
import {Link} from 'react-router-dom';
//import ReactDOM from 'react-dom';

class Navbar extends React.Component {
  render() {
    return <div className="container-fluid nav-pad">
            <nav className="navbar navbar-expand-md">
                <Link to="/"><img className="cm-logo" src="images/logo.png" alt="logo" /></Link>
                <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"><i className="fa fa-bars" aria-hidden="true"></i></span>
                </button>
            
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ml-auto">
                        <Link className="nav-item nav-link" to="/terms">Terms &amp; Conditions</Link>
                        <Link className="nav-item nav-link" to="/contact">Contact us</Link>
                        <Link className="nav-item nav-link" to="/registered">Already Registered?</Link>
                    </div>
                </div>
            </nav>
        </div>
        ;
   
}
}

export default Navbar;