
import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import {Link} from 'react-router-dom';
import Slider from '../components/Slider';
import Winwheel from "winwheel";
import Service from '../services/Service';



//import ReactDOM from 'react-dom';

class SpinWheel extends React.Component {
     constructor(props){
         super(props);
         this.state = {
            prize_info : "none",
            screen_size:0,
             button_val : true,
             theWheel : new Winwheel({}),
             segment: [],
             winning_prize:8,
             reward_id:18,
             page_to_go:0
            }
            this.getData()
            var screenwidth = window.innerWidth;
            

            const wheelcount = 0;
            const win_prize = "";
            
         //var theWheel;
       
         
     }
      getData(){
        Service.getServiceWithToken('/user/wof?campaign_id=1' )
            .then((res)=>{

              
                for(let i =0 ; i< res.data.data.reward.length; i++){

                   let as ={ 'fillstyle':'radGradient',
                        'text': res.data.data.reward[i].messages

                        }
                    this.state.segment.push((as));
                    this.setState({winning_prize: 7 + ((res.data.data.stop_at - 1) * 36)})
                    if(res.data.data.stop_at == 8){
                        sessionStorage.setItem("setPage", "/thanksamc")
                    }else{
                        sessionStorage.setItem("setPage", "/billupload")
                    }
                    
                    this.setState({reward_id: res.data.data.reward_id})
                }
                
            }).then(()=>{

                this.addSegment()

            }).catch(()=>{

                window.location = "/"
            })
     }
     componentDidMount() {
         let width 
         let height
         let size 
         let tie
         let centerx;
         let centery;
        if(window.innerWidth <= 600){
            
            document.querySelector("#canvasContainer canvas").style.marginTop = "28px"
            document.querySelector("#canvasContainer canvas").style.marginLeft = "-5px"
            document.querySelector("#canvasContainer canvas").style.width = "275px"
            document.getElementById("canvasContainer").style.width = "320px"
            document.getElementById("canvasContainer").style.height = "325px"
           
             width = 395
             height = 395
             size = 125
             tie = "drawTriangle()"
             centerx = 200
             centery= 200
            

            
        }else{
            document.querySelector("#canvasContainer canvas").style.marginTop = "40px"
            document.querySelector("#canvasContainer canvas").style.marginLeft = "2px"
            document.getElementById("canvasContainer").style.width = "450px"
            document.getElementById("canvasContainer").style.height = "465px"
            
            width = 400
            height = 400
            size = window.innerWidth/2
            centerx = 200;
            centery = 200;
            tie = "drawTriangle2()"
        }
        let canvas = document.getElementById('myCanvas');
        let ctx = canvas.getContext('2d');
        canvas.height = height;
        canvas.width = width;
        
        
        this.setState({theWheel : new Winwheel({
            canvasId: "myCanvas",
            'numSegments'  : 0,
            'lineWidth'   : .5,
            'drawMode'          : 'image',
            'textMargin'     : 5,         // Number of segments
            'outerRadius'  : size,
            'imageOverlay' : true,        // The size of the wheel.
            'centerX'      : centerx,       // Used to position on the background correctly.
            'centerY'      : centery,
            'textFontSize' : 10,        // Font size.
            'segments'     :            // Definition of all the segments.
            [
               
            ],
            
            'animation' :               // Definition of the animation
            {
                'type'     : 'spinToStop',
                'duration' : 10,
                'spins'    : 100,
                'callbackFinished' : this.alertPrize,
                'direction'    : 'clockwise',
                
                
            }
        })}, () => {
            
            let sizing  = window.innerWidth;
            
            if(sizing <= 750){
               
                
                
            }else{
               
            }
            let theWheel1 = this.state.theWheel
        let loadedImg = new Image();
 
            // Create callback to execute once the image has finished loading.
            loadedImg.onload = function()
            {
                theWheel1.wheelImage = loadedImg;    // Make wheelImage equal the loaded image object.
                theWheel1.draw();                    // Also call draw function to render the wheel.
            }
 
            loadedImg.src = "images/inner_final.png";
            
        });
        
        
       }
    addSegment()
    {
        let canvas = document.getElementById('myCanvas');
        let ctx = canvas.getContext('2d');
        let canvasCenter = canvas.height / 2;
        let radGradient = ctx.createRadialGradient(canvasCenter, canvasCenter, 50, canvasCenter, canvasCenter, 250);
        let radGradientGold = ctx.createRadialGradient(canvasCenter, canvasCenter, 50, canvasCenter, canvasCenter, 250);
        // Add the colour stops - 0.0 should be the first, 1.0 the last, others in between.
        radGradient.addColorStop(0, "#D41212");
        radGradient.addColorStop(0.5, "#A20000");
        radGradient.addColorStop(1, "#E10000");
        radGradientGold.addColorStop(0, "#F9DF7B");
        radGradientGold.addColorStop(0.5, "#B57E10");
        radGradientGold.addColorStop(1, "#B78113");
        

        let theWheel1 = this.state.theWheel
        let loadedImg = new Image();
 
            // Create callback to execute once the image has finished loading.
            loadedImg.onload = function()
            {
                theWheel1.wheelImage = loadedImg;    // Make wheelImage equal the loaded image object.
                theWheel1.draw();                    // Also call draw function to render the wheel.
            }
 
            // Set the image source, once complete this will trigger the onLoad callback (above).
            loadedImg.src = "images/inner_final.png";
        for(let i = 0; i < this.state.segment.length; i++ ){
          

        }
       
        let sizing  = window.innerWidth;
            if(sizing <= 750){
                ctx.translate(0, -80);
                theWheel1.draw();
                this.drawTriangle();
            }else{
                ctx.translate(0, 0);
                theWheel1.draw();
                this.drawTriangle2();
            }
        
        
    }


        drawTriangle()
            {
               
              
                let tcanvas = document.getElementById('myCanvas');
                let ctx = tcanvas.getContext('2d');
                ctx.translate(0, 80);
                ctx.strokeStyle = '#490936';     // Set line colour.
                ctx.fillStyle   = '#490936';     // Set fill colour.
                ctx.lineWidth   = 2;
                ctx.beginPath(20,5);              // Begin path.
                ctx.moveTo(171, 5);           // Move to initial position.
                ctx.lineTo(230, 5);           // Draw lines to make the shape.
                ctx.lineTo(200, 30);
                ctx.lineTo(171, 5);
                ctx.stroke();                 // Complete the path by stroking (draw lines).
                ctx.fill();                   // Then fill.
               
               
            }

            drawTriangle2(){
                       
                let tcanvas = document.getElementById('myCanvas');
                let ctx = tcanvas.getContext('2d');
        
                ctx.strokeStyle = '#490936';     // Set line colour.
                ctx.fillStyle   = '#490936';
                ctx.lineWidth   = 2;
                ctx.beginPath();              // Begin path.
                ctx.moveTo(171, 20);           // Move to initial position.
                ctx.lineTo(230, 20);           // Draw lines to make the shape.
                ctx.lineTo(200, 60);
                ctx.lineTo(171, 20);
                ctx.stroke();                 // Complete the path by stroking (draw lines).
                ctx.fill();    
            }
        
       start(){
    
        let tcanvas = document.getElementById('myCanvas');
        let ctx = tcanvas.getContext('2d');
        
        let sizing  = window.innerWidth;
            
            if(sizing <= 750){
                //ctx.translate(0, -80);
                
            }
        
        let theWheel1 = this.state.theWheel;
        
        theWheel1.animation.stopAngle = (this.state.winning_prize);
        this.state.theWheel.startAnimation(); 
       
         this.setState({button_val : false})
         this.win_prize = this.state.winning_prize;
         sessionStorage.setItem("RewardId", this.state.reward_id)
        }

        alertPrize(){
            
          
            let a = this.win_prize
           
            let sizing  = window.innerWidth;
           
            if(sizing <= 750){
                //eval("drawTriangle()");
                
                
            }else{
                //eval("drawTriangle2()");
            }
            //sessionStorage.setItem("Reward", a);
            Service.postWithToken('/user/spinthewheel',{campaign_id: 1, reward_id: sessionStorage.getItem("RewardId")}
            ).then((res)=>{
                
                sessionStorage.setItem("img_url", res.data.data.image);
                sessionStorage.setItem("message", res.data.data.message);
                sessionStorage.setItem("winner_id", res.data.data.winner_id)
                
                setTimeout(function(){ 
                    window.location = "/winningarea";
                }, 3000);
                    
                
                

            }).catch((err)=>{
                console.log(err.response);
                window.location = "/"
            })
            
        }
         
    
      

  render() {
   let button;
   if(this.state.button_val){
    button = <button className="btn spin-button-design" onClick={()=> this.start()} >Spin</button>
   }else{
       
    button = ""
   }
   
  

    return <>
    <Navbar />
    <Slider />
    




 <div className="container-fluid p-0">
  <div className="col-sm-6 col-md-12 col-lg-12 col-xl-12 p-0 congrats-home-container spin-bg-color">






   <div className=" upload-left-section spin-left">

   
   
   </div>







 <div className="upload-right-section spin-right">
    <div className="text-center">
        
      

      </div>
 </div>
  </div>

</div>






<div class="container-fluid Otp-bg p-0">
      
           <div class="container">
               <div class="row">

                <div class="col-lg-6 col-lg-p0 mob-show">
                    <div class="text-center">
                     <img src="images/spin-mob-left.png" alt="" class="spin-mob-img" />
                    </div>  
                 </div>

                   <div class="col-lg-6 text-center">
                   <div className ="canvas-container" id="canvasContainer">
                    <canvas id="myCanvas"  style={{marginTop:"50px",marginLeft:"50px"}}>
                        <p align="center">Sorry, your browser doesn't support canvas. Please try another.</p>
                    </canvas>
                    </div>
                   </div>

                   <div class="col-lg-6 col-lg-p0">
                     <div class="text-center">
                      <img src="images/spin-wheel-right-button.png" alt="" class="spin-right-img mob-hide" />
                      {button} 
                     </div>  
                  </div>

            
               </div>
           </div>
   
   </div>
 </>
 ;
   
}  
    
}

export default SpinWheel;