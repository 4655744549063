import React from 'react';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
import ReactFormInputValidation from 'react-form-input-validation';
import Service from '../services/Service';
import {Link} from 'react-router-dom';
//import ReactDOM from 'react-dom';

class Home extends React.Component {
    constructor(props) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("phone");
        sessionStorage.clear()
        super(props);
        this.state = {
          serial_error:"",
          phone_error:"",
          submit_disable:false,
          fields: {
            campaign_id: 1,
            product_serial_number:"",
            customername: "",
            email: "",
            mobile: "",
            terms:"",
            state_id:"",
            state_list:""
          },
          errors: {
              mobile:""
          }
        };
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
          product_serial_number:"required",
          customername: "required",
            email: "email",
            mobile: "required|numeric|digits_between:10,10",
            terms:"required",
            state_id:"required"

        });
        let messages = ReactFormInputValidation.getMessages("en");
        messages.required = "Required Field";
        ReactFormInputValidation.setMessages("en", messages);
        this.form.onformsubmit = (fields) => {
        this.setState({serial_error: ""})
        this.setState({phone_error: ""})
        this.setState({submit_disable : true})
       
        sessionStorage.setItem("phone", fields.mobile);
        Service.register('/user/registration', fields)
        .then((res)=>{
        
        this.setState({submit_disable : false});
        sessionStorage.setItem("name", this.state.fields.customername);
        sessionStorage.setItem("mobile", this.state.fields.mobile);
        sessionStorage.setItem("customer_id", res.data.data.customer_id)
        
        window.location = '/otp';
        }).catch((err)=>{
          
          if(err.response.data.data.message == "1"){
            this.setState({serial_error : "Invalid Serial Number"})
          }if(err.response.data.data.message == "2"){
            this.setState({serial_error : "Serial Number Already Exist"})

          }if(err.response.data.data.message == "3"){
            this.setState({phone_error : "Phone Number Already Exist"})
          }
          this.setState({submit_disable : false})
          
        })
        
        }
        
    }
    changeRoute(){


    }
    componentDidMount(){
      Service.GetService('/user/getstatelist')
        .then((res)=>{
          let a = "<option selected>Choose State</option>";
         
          for(let i =0; i < res.data.data.length; i++){
            a+= "<option value='"+res.data.data[i].id+"'>"+res.data.data[i].statename+"</option>"
          }
          this.setState({state_list: a});
          let b = (this.state.state_list);
          document.getElementById("state").innerHTML = b;
          
        })
    }


  render(){
    
    return <>
    <Navbar/>
    <Slider/>
   
   <div className="container-fluid home-bg p-0">
      
      <div className="container">
          <div className="row">

              <div className="col-lg-6 text-center">
                 <img src="images/homepage-desk-left-img.png" alt="" className="home-left-img" />
                 <img src="images/homepage-mob-left-img.png" alt="" className="home-mob-left-img" />
              </div>

              <div className="col-lg-6 col-lg-p0">
                <div className="text-center">
                 <img src="images/spin-desk.png" alt="" className="home-spin-img" />
                </div>
               
               <form className="floating-form-design" onSubmit={this.form.handleSubmit}>
                 <div className="floating-form">
                       <h3 className="enter-details">Enter Your Details</h3>
                   <div className="floating-label">      
                     <input className="floating-input" type="text" placeholder=" " 
                     name="product_serial_number"
                     onBlur={this.form.handleBlurEvent}
                    onChange={this.form.handleChangeEvent}
                    value={this.state.fields.product_serial_number}
                      />
                     <span className="highlight"></span>
                     <label>Enter your Product Serial No</label>
                     <label className="error">
                        {this.state.errors.product_serial_number ?  this.state.errors.product_serial_number : ""}
                    </label>
                    <label className="error">
                      {this.state.serial_error}
                    </label>
                   </div>
                   
               
                   <div className="floating-label">      
                     <input className="floating-input" type="text" placeholder=" " 
                     name="customername"
                     onBlur={this.form.handleBlurEvent}
                     onChange={this.form.handleChangeEvent}
                     value={this.state.fields.customername}
                     />
                     <span className="highlight"></span>
                     <label>Enter your Name</label>
                     <label className="error">
                        {this.state.errors.customername ? "Required Field" : ""}
                    </label>
                   </div>
                   

                   <div className="floating-label">      
                     <input className="floating-input" type="tel" placeholder=" " 
                     name="mobile"
                     maxLength="10"
                     onBlur={this.form.handleBlurEvent}
                     onChange={this.form.handleChangeEvent}
                     value={this.state.fields.mobile}
                     />
                     <span className="highlight"></span>
                     <label>Enter your Mobile Number</label>
                     <label className="error">
                        {this.state.errors.mobile ? "Please enter a valid 10-digit mobile number" : ""}
                    </label>
                    <label className="error" style={{top:"-30px"}}>
                      {this.state.phone_error}
                    </label>
                   </div>
                   
                    
                   <div className="floating-label  upload-label">  
                        <select className="floating-select"  id="state" 
                        name="state_id"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.state_id}
                        >
                          
                        </select>
                        <span className="highlight"></span>
                        <label>Select your State</label>
                        <label className="error">
                        {this.state.errors.state_id ? this.state.errors.state_id : ""}
                      </label>
                      </div>
                      
                   <div className="floating-label">      
                     <input className="floating-input" type="email" placeholder=" " 
                     name="email"
                     onBlur={this.form.handleBlurEvent}
                     onChange={this.form.handleChangeEvent}
                     value={this.state.fields.email}
                     />
                     <span className="highlight"></span>
                     <label>Enter your Email ID</label>
                     <label className="error">
                        {this.state.errors.email ? this.state.errors.email : ""}
                    </label>
                   </div>
                   
                   <div className="floating-label agree-text">
                     <input className="form-check-input" type="checkbox" 
                     name="terms"
                     value="terms"
                     onChange={this.form.handleChangeEvent}
                     /> I agree to <Link to="/terms">Terms & Conditions</Link> <br />
                     <label className="error">
                        {this.state.errors.terms ? "Please accept terms & conditions" : ""}
                    </label>
                   </div>
                  <div className="text-center">
                   <button type="submit" className="btn submit-button-design" disabled={this.state.submit_disable}>Submit</button>
                  </div>
                 
               
               </div>
               </form>
             </div>
          </div>
      </div>

</div>

 </>
 ;
   
};
}

export default Home;