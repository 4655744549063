
import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
import ReactFormInputValidation from 'react-form-input-validation';
import Service from '../services/Service';

//import ReactDOM from 'react-dom';

class Uploadbill extends React.Component {
  
     constructor(props){
       if(sessionStorage.getItem("name") == null){
        window.location = "/";
       }
         super(props);
        
            this.state = {
              later:false,
              fileError:"",
              fields: {
                campaign_id: 1,
                installation_number: "",
                address: "",
                bill:"",
                name:"",
                mobile:""
              },
              errors: {
                  
              }
            };
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
        
            installation_number:"required",
            address: "required"
        });
        this.form.onformsubmit = (fields) => {
          //this.setState({fileError: ""})
          (this.convertBase(document.querySelector('#newBill').files[0]).then((res)=>{
            if(res != null){

            this.state.fields.bill = res;
            Service.postWithToken('/user/uploadbill',fields )
            .then((res)=>{
            
           // this.state.fields.bill = ( this.convertBase(this.state.fields.bill))
            
            window.location = "/thanksinvoice"
            }).catch(()=>{

              window.location = "/";
            })
            }
            
            

          }))
          
        }
        this.setState({name: sessionStorage.getItem("name")});
        this.setState({mobile: sessionStorage.getItem("mobile")})
        
       
     }
      toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      if(file == null){
        this.setState({fileError: "Please upload the bill"})
      }else{
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }
     
  });
     async  convertBase(file) {
      //const file = document.querySelector('#myfile').files[0];
      
      return this.toBase64(file);
     }
      
    
 
    componentDidMount(){
      document.getElementById("btn-upload").addEventListener('click', function(){
        document.getElementById("newBill").click();
      })
      
      var a = document.getElementById("newBill").addEventListener('change', function(){
      document.getElementById("file-err").innerText = ""
      var a = document.getElementById("newBill").value
      a = a.split("\\");
      document.getElementById("filename").innerText = a[a.length-1]
      
      })
      document.getElementById("later-btn").addEventListener("click",function(){

        window.location = "/"
      })


    }
   

  render() {
  
 

    return <>
    <Navbar />
    <Slider />
   

<div class="container-fluid  upload-bg p-0">
      
           <div class="container">
               <div class="row">
                <form class="upload-floating-form-design" onSubmit={this.form.handleSubmit}>
                    <div class="text-center">
                        <img src="images/lamp.png" alt="" class="upload-lamp" />
                    </div>
                    
                    
                    
                    <div class="upload-floating-form">
                          <h3 class="enter-details upload-details">Enter Your Details</h3>
                          <div class="text-center">
                          <label className="error" id="file-err">
                              {this.state.fileError}
                          </label>
                        <p id="filename" className="text-white"></p>
                        <button type="button"  class="btn upload-button-design" id="btn-upload">Upload your invoice</button>
                        <input type="file"  style={{display:"none"}} id="newBill" />
                        
                        
                           </div>
                      <div class="floating-label upload-label">      
                        <input class="floating-input" type="text" placeholder=" "
                        name="installation_number" 
                         onBlur={this.form.handleBlurEvent}
                         onChange={this.form.handleChangeEvent}
                         value={this.state.fields.installation_number}
                         />
                        <span class="highlight"></span>
                        <label>Enter your Installation number</label>
                      </div>
                      <label className="error">
                        {this.state.errors.installation_number ? "Required Field" : ""}
                      </label>
                  
                      <div class="floating-label upload-label">      
                        <textarea class="floating-input floating-textarea " placeholder=" " row="3"
                        name="address"
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        value={this.state.fields.address}
                        ></textarea>
                        <span class="highlight"></span>
                        <label>Enter your shipping address</label>
                      </div>
                      <label className="error">
                        {this.state.errors.address ? "Required Field" : ""}
                      </label>
                     <div class="text-center">
                      <button type="submit" class="btn submit-button-design">Submit</button>
                      <button type="button" id="submit-later" class="btn submit-later-design" data-toggle="modal" data-target="#LaterModal">Submit Later</button>
                     </div>
                    
                  
                  </div>
                  </form>
               </div>
           </div>
   
   </div>

  <div class="modal fade" id="LaterModal" role="dialog">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close text-white" data-dismiss="modal">&times;</button>
          
        </div>
        <div class="modal-body">
          <p>If you don't have a purchase invoice or an installation number yet, please come back and visit the 'Already Registered' tab on the Home page</p>
        </div>
        <div class="modal-footer">
        <button type="button"  id="later-btn" class="btn btn-default text-white">Ok</button>
          <button type="button" class="btn btn-default text-white" data-dismiss="modal">Cancel</button>
          

        </div>
      </div>
    </div>
  </div>

 </>
 ;
   
}  
    
}

export default Uploadbill;