
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
//import ReactDOM from 'react-dom';

class Contact extends React.Component {
  constructor(props){
    super(props);
    sessionStorage.clear()
  }
  render() {
    return <>
    <Navbar/>
    <Slider/>
    <div class="container-fluid  upload-bg p-0">
      
           <div class="container">
               <div class="row">
                   <div class="col-lg-12">
                    <div class="text-center thank-sec">
                        <img src="images/design-top.png" alt="" class="design-pattern" />
                        <img src="images/mob-design-pattern.png" alt="" class="mob-design-pattern" /><br />
                        <img src="images/lamp.png" alt="" class="thank-lamp-img" /><br />
                        <h1 class="won-color-h1">Contact Us</h1>
                       
                        <p class="thank-para"> PREMIER SALES PROMOTIONS PVT. LTD.<br />
                            Bangalore – 560001<br />
                            For any queries, call us on 080 4055 4868 from Mon-Sat, between 10:30 AM to 05:30 PM or email us at feedback@bigcity.in</p>
                        
                    </div>
                   </div>
             
               </div>
           </div>
   
   </div>
 </>
 ;
   
}
}

export default Contact;