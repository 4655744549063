import React from 'react';

class Slider extends React.Component {
  render() {
    return  <>
    </>;
   
}
}

export default Slider;