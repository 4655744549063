
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Slider from '../components/Slider';
//import ReactDOM from 'react-dom';

class Thankyou extends React.Component {
  constructor(props){
    
    super(props);
    if(sessionStorage.getItem("name") == null){
      window.location = "/";
     }
    sessionStorage.clear()
    
  }
  render() {
    sessionStorage.clear()
    return <>
    <Navbar/>
    <Slider/>
  


<div className="container-fluid  upload-bg p-0">
      
           <div className="container">
               <div className="row">
                   <div className="col-lg-12">
                    <div className="text-center thank-sec">
                        <img src="images/design-top.png" alt="" className="design-pattern" />
                        <img src="images/mob-design-pattern.png" alt="" className="mob-design-pattern" /><br />
                        <img src="images/lamp.png" alt="" className="thank-lamp-img" /><br />
                        <h1 className="won-color-h1">Thank you</h1>
                        <h4 className="congrats-color-h4">For Participating</h4>
                        <p className="thank-para">We have received your invoice details for Diwali ka Tyohar offer.
      We will verify the invoice details and notify the status within 48-business hours.</p>
                        
                    </div>
                   </div>
             
               </div>
           </div>
   
   </div>

 </>
 ;
   
}
}

export default Thankyou;